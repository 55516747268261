import { makeStyles } from "@material-ui/core/styles"

const tourCardStyles = makeStyles(theme => ({
  card: {
    maxWidth: "312px",
  },
  dFlex: {
    display: "flex",
    textAlign: "center",
    alignItems: "center",
  },
  topMargin: {
    marginTop: 10,
  },
  leftBMargin: {
    marginLeft: "5px",
    marginBottom: "2px",
  },
  leftMargin: {
    marginLeft: "5px",
  },
  title: {
    color: "#010204",
    fontFamily: "Mabry Pro",
    fontSize: "20px",
    fontWeight: "500",
    lineHeight: "26px",
  },
  subtitle: {
    color: "#010204",
    fontWeight: "300",
    letterSpacing: "0.5px",
    lineHeight: "16px",
    fontFamily: "MabryProLight",
    fontSize: "16px",
  },
  uppercase: {
    textTransform: "uppercase",
  },
  h500: {
    height: 350,
  },
  arrow: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    borderRadius: "50%",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: "#fff",
    },
    transition: "all 0.3s ease",
  },
}))
export default tourCardStyles
