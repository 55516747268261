import React, { useEffect, useState } from "react"
import Typography from "@material-ui/core/Typography"
import tourCardStyles from "../TourCards/TourCard.style"
import Card from "@material-ui/core/Card"
import CardActionArea from "@material-ui/core/CardActionArea"
import "react-multi-carousel/lib/styles.css"
import CardContent from "@material-ui/core/CardContent"
import { withWidth } from "@material-ui/core"
import Axios from "axios"
import { apiUrl } from "../../config/api"
import "./index.scss"
import useWindowWidth from "../../utils/useWindowWidth"
import Img from "gatsby-image"
import ReactHtmlParser from "react-html-parser"


const TourDetailCard = props => {
  const [prevDisable, setPrevDisable] = useState(true)
  const [nextDisable, setNextDisable] = useState(true)
  const [additionalTransform, setAdditionalTransform] = useState(0)
  const [gutter, setGutter] = useState(4)
  const windowWidth = useWindowWidth()
  const [minWidth, setMinWidth] = useState(312)
  const [currentSlide, setCurrentSlide] = useState(0)
  const classes = tourCardStyles()
  const { id } = props
  const [tours, setTours] = useState(props.cards)

  useEffect(() => {
    if (tours.length > 4) {
    }
    if (Math.ceil(windowWidth[0] / 4) > 0) {
      if (windowWidth[0] <= 576) {
        setMinWidth((windowWidth[0] * 86.888) / 100 - 15)
        setGutter(1)
      } else if (windowWidth[0] <= 768) {
        setGutter(2)
        setMinWidth(Math.ceil(windowWidth[0] / 2) - 70)
      } else if (windowWidth[0] <= 1200) {
        setGutter(3)
        setMinWidth(Math.ceil(windowWidth[0] / 3) - 70)
      } else {
        setMinWidth(Math.ceil(windowWidth[0] / 4) - 70)
      }
    }
  }, [tours.length, windowWidth])

  useEffect(() => {
    if (windowWidth[0] <= 576 && tours.length > 1) {
      setNextDisable(false)
    } else if (windowWidth[0] <= 768 && tours.length > 2) {
      setNextDisable(false)
    } else if (windowWidth[0] <= 1200 && tours.length > 3) {
      setNextDisable(false)
    } else if (tours.length > 4) {
      setNextDisable(false)
    }
  }, [tours, windowWidth])

  useEffect(() => {
    Axios.get(apiUrl + "/tours")
      .then(response => {
        let data = response.data.filter(item => item._id !== id)
        setTours(data)
      })
      .catch(() => {})
  }, [id])

  return (
    <div>
      <section className="section-carousel">
        <div className="parent-div">
          <div className="multi-carousel-main">
            <div className="heading-bar">
              <div className="top-heading">
                <Typography
                  style={{
                    fontSize: "40px",
                    fontFamily: "Clearface",
                    letterSpacing: "-0.2px",
                  }}
                >
                  {props.title}
                </Typography>
                {/* <Typography
                  style={{
                    fontSize: "16px",
                    letterSpacing: "0px",
                    fontFamily: "MabryProRegular",
                  }}
                >
                  {ReactHtmlParser(subtitle)}
                </Typography> */}
              </div>
              <div className="carousel-button-group">
                <div
                  className={prevDisable ? "disable button-one" : "button-one"}
                  onClick={() => {
                    if (currentSlide <= tours.length - gutter + 1) {
                      if (!prevDisable) {
                        if (currentSlide === 1) {
                          setPrevDisable(true)
                          setNextDisable(false)
                          setCurrentSlide(currentSlide => currentSlide - 1)
                          if (currentSlide === 1 && windowWidth[0] > 576) {
                            setAdditionalTransform(
                              additionalTransform =>
                                additionalTransform + minWidth
                            )
                          } else {
                            setAdditionalTransform(
                              additionalTransform =>
                                additionalTransform + minWidth
                            )
                          }
                        } else {
                          setCurrentSlide(currentSlide => currentSlide - 1)
                          setNextDisable(false)
                          setAdditionalTransform(
                            additionalTransform =>
                              additionalTransform + minWidth
                          )
                        }
                      }
                    }
                  }}
                  style={{ opacity: prevDisable ? 0.3 : 1 }}
                >
                  <div className={classes.arrow}>
                    <SliderLeftArrow width="20" height="20" />
                  </div>
                </div>
                <div
                  onClick={() => {
                    if (!nextDisable) {
                      if (currentSlide === tours.length - gutter) {
                        setNextDisable(true)
                        setCurrentSlide(currentSlide => currentSlide + 1)

                        setAdditionalTransform(
                          additionalTransform => additionalTransform - minWidth
                        )
                      } else {
                        setPrevDisable(false)
                        setCurrentSlide(currentSlide => currentSlide + 1)
                        if (currentSlide === 0 && windowWidth[0] > 576) {
                          setAdditionalTransform(
                            additionalTransform =>
                              additionalTransform - minWidth
                          )
                        } else {
                          setAdditionalTransform(
                            additionalTransform =>
                              additionalTransform - minWidth
                          )
                        }
                      }
                    }
                  }}
                  className="button-two"
                  style={{
                    opacity: nextDisable ? 0.3 : 1,
                  }}
                >
                  <SliderRightArrow width="20" height="20" />
                </div>
              </div>
            </div>

            <div
              className="carousel-sub-main"
              style={{ transform: `translateX(${additionalTransform}px)` }}
            >
              {tours.length > 0 &&
                tours.map(
                  (
                    {
                      node: {
                        tourLocationDescription,
                        tourTitle,
                        cardDescription,
                        tourMainImage,
                        tourPricingReference,
                      },
                    },
                    index
                  ) => {
                    // const {
                    //   // _id,
                    //   // title,
                    //   // img,
                    //   // location,
                    //   // category,
                    //   // detailcard,
                    //   // priceY,
                    //   // priceE,
                    // } = tour;

                    return (
                      <div key={index}>
                        <Card
                          className={classes.card}
                          style={{ marginRight: 20 }}
                        >
                          <CardActionArea>
                            {tourMainImage?.fluid ? (
                              <Img
                                component="img"
                                alt={tourTitle}
                                style={{ height: "172px", width: "312px" }}
                                fluid={tourMainImage?.fluid}
                              />
                            ) : (
                              <img
                                component="img"
                                alt={tourTitle}
                                style={{ height: "172px", width: "312px" }}
                                src={tourMainImage?.fluid?.src}
                              />
                            )}
                            <CardContent>
                              <Typography
                                style={{
                                  fontSize: "12px",
                                  fontFamily: "MabryProLight",
                                }}
                              >
                                {tourLocationDescription}
                              </Typography>
                              <Typography
                                style={{
                                  fontSize: "20px",
                                  fontFamily: "MabryProBold",
                                }}
                                gutterBottom
                                variant="h5"
                                component="h2"
                                className={classes.title}
                              >
                                {tourTitle}
                              </Typography>
                              <Typography
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "MabryProLight",
                                  overflow: "hidden",
                                  display: "-webkit-box",
                                  webkitLineClamp: "2",
                                  webkitBoxOrient: "vertical",
                                  minHeight: 32,
                                }}
                                className={classes.subtitle}
                              >
                                {ReactHtmlParser(
                                  cardDescription?.cardDescription
                                )}
                              </Typography>
                              <div
                                className={[classes.dFlex]}
                                style={{ marginTop: "16px" }}
                              >
                                <div className={[classes.dFlex]}>
                                  <Typography
                                    style={{
                                      fontSize: "20px",
                                      fontFamily: "MabryProBold",
                                    }}
                                    gutterBottom
                                    variant="h5"
                                    component="h2"
                                    className={classes.title}
                                  >
                                    {!!tourPricingReference?.isFixedPriceTour
                                      ? tourPricingReference.fixedTourPrice
                                      : tourPricingReference.perPersonPricing}
                                  </Typography>
                                  <Typography
                                    style={{
                                      fontSize: "12px",
                                      fontFamily: "MabryProLight",
                                    }}
                                    className={[
                                      classes.leftBMargin,
                                      classes.subtitle,
                                    ]}
                                  >
                                    EUR
                                  </Typography>
                                </div>
                                {/* <div
                                  style={{
                                    border: "1px solid gray",
                                    borderStyle: "dashed",
                                    height: 15,
                                    marginLeft: 10,
                                    marginRight: 10,
                                  }}
                                />
                                <div className={[classes.dFlex]}>
                                  <Typography
                                    style={{
                                      fontSize: "20px",
                                      fontFamily: "MabryProBold",
                                    }}
                                    gutterBottom
                                    variant="h5"
                                    component="h2"
                                    className={classes.title}
                                  >
                                    {props.moneyT}
                                  </Typography>
                                  <Typography
                                    style={{
                                      fontSize: "12px",
                                      fontFamily: "MabryProLight",
                                    }}
                                    className={[
                                      classes.leftBMargin,
                                      classes.subtitle,
                                    ]}
                                  >
                                    {props.currencyT}
                                  </Typography>
                                </div> */}
                              </div>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </div>
                    )
                  }
                )}
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default withWidth()(TourDetailCard)

const SliderLeftArrow = ({ width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    enableBackground="new 0 0 512 512"
    version="1.1"
    viewBox="0 0 512 512"
    xmlSpace="preserve"
    width={width}
    height={height}
  >
    <path d="M501.333 245.333H36.417l141.792-141.792c4.167-4.167 4.167-10.917 0-15.083-4.167-4.167-10.917-4.167-15.083 0l-160 160c-4.167 4.167-4.167 10.917 0 15.083l160 160a10.634 10.634 0 007.542 3.125c2.729 0 5.458-1.042 7.542-3.125 4.167-4.167 4.167-10.917 0-15.083L36.417 266.667h464.917c5.896 0 10.667-4.771 10.667-10.667s-4.772-10.667-10.668-10.667z"></path>
  </svg>
)

export const SliderRightArrow = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 512 512"
      version="1.1"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
      width={width}
      height={height}
    >
      <path d="M508.875 248.458l-160-160c-4.167-4.167-10.917-4.167-15.083 0-4.167 4.167-4.167 10.917 0 15.083l141.792 141.792H10.667C4.771 245.333 0 250.104 0 256s4.771 10.667 10.667 10.667h464.917L333.792 408.458c-4.167 4.167-4.167 10.917 0 15.083a10.634 10.634 0 007.542 3.125c2.729 0 5.458-1.042 7.542-3.125l160-160c4.166-4.166 4.166-10.916-.001-15.083z"></path>
    </svg>
  )
}


